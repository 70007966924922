import React, { useRef, useEffect, useState } from 'react';
import './App.css';
import { BoxObj } from './component/BoxObj';
import Light from './component/Light';
import Box_group from './component/Box_group';
import { Canvas, extend, useFrame, useThree } from 'react-three-fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

// import 'react-dat-gui/build/react-dat-gui.css';
import { Text } from 'troika-three-text';
import fonts from './component/font';

import _ from 'lodash';

extend({ OrbitControls });
extend({ Text });

const text = 'ULTRAZONE FACTORY';
const text2 = 'ULTRAZONE FACTORYでは';
const text3 = '宮崎の地域に根ざしたサイト・システムをご提案します';
const text4 = 'お気軽にお問い合わせください。';

function App() {
    const ref = useRef();
    const opts = {
        font: 'Quicksand',
        fontSize: 10,
        fontWeight: 700,
        color: '#0081cc',
        maxWidth: 200,
        lineHeight: 1,
        letterSpacing: 0,
        textAlign: 'justify',
        position: [0, 40, 12],
    };
    // console.log(ref);
    if (window.innerWidth <= 1000) {
        opts.fontSize = 5;
    }
    return (
        <>
            <Canvas>
                {/* <Camera
                position={[0, 0, 100]}
                fov={90}
                aspect={[window.innerWidth / window.innerHeight]}
                near={3}
                far={2000}
            /> */}
                <CameraControls />
                {/* <axesHelper args={[30, 15, 15]} /> */}
                <Light />
                <GroundPlane />
                <BackDrop />
                <Box_group />
                <text
                    ref={ref}
                    position-z={12}
                    // rotation={rotation}
                    {...opts}
                    text={text}
                    font={fonts[opts.font]}
                    anchorX="center"
                    anchorY="middle"
                    castShadow
                />
                <BoxObj position={[0, 0, 0]} />
            </Canvas>
            <div className={'text'}>
                <div>{text2}</div>
                <div>{text3}</div>
                <div>{text4}</div>
            </div>
            <small>© ULTRAZONE FACTORY</small>
        </>
    );
}

function Camera(props) {
    const ref = useRef();
    const { setDefaultCamera } = useThree();
    // // Make the camera known to the system
    useEffect(() => setDefaultCamera(ref.current), []);
    // // Update it every frame
    // useFrame(() => ref.current.updateMatrixWorld());
    return <perspectiveCamera ref={ref} {...props} />;
}

const CameraControls = () => {
    // Get a reference to the Three.js Camera, and the canvas html element.
    // We need these to setup the OrbitControls class.
    // https://threejs.org/docs/#examples/en/controls/OrbitControls

    const {
        camera,
        gl: { domElement },
    } = useThree();

    // Ref to the controls, so that we can update them on every frame with useFrame
    const controls = useRef();
    useFrame(() => controls.current.update());
    return (
        <orbitControls
            ref={controls}
            args={[camera, domElement]}
            autoRotate={false}
            enableZoom={true}
            enablePan={false}
            enableRotate={false}
            minDistance={100}
            maxDistance={150}
        />
    );
};

function GroundPlane() {
    return (
        <mesh receiveShadow rotation={[5, 0, 0]} position={[0, -100, 0]}>
            <planeBufferGeometry attach="geometry" args={[1000, 1000]} />
            <meshStandardMaterial attach="material" color="white" />
        </mesh>
    );
}

function BackDrop() {
    return (
        <mesh receiveShadow position={[0, -1, -100]}>
            <planeBufferGeometry attach="geometry" args={[1000, 1000]} />
            <meshStandardMaterial attach="material" color="white" />
        </mesh>
    );
}

export default App;
